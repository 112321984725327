// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createReportContainer__container {
  height: 100%;
  width: 100%;
  padding: 24px;
}
.createReportContainer__container .title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
}
.createReportContainer__container .infinite-scroll-component__outerdiv {
  margin-top: -36px;
}
.createReportContainer__container .graph_container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  overflow: hidden !important;
}
@media (max-width: 1200px) {
  .createReportContainer__container .graph_container {
    grid-template-columns: 1fr;
  }
}

.no-widget-found {
  font-size: 18px;
  line-height: 14px;
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateReportContainer/createReportContainer.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,WAAA;EACA,aAAA;AAAF;AAEE;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,iBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;EACA,2BAAA;AAAJ;AACI;EACE;IACE,0BAAA;EACN;AACF;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,WAAA;AADF","sourcesContent":[".createReportContainer__container {\n  height: 100%;\n  // background-color: white;\n  width: 100%;\n  padding: 24px;\n\n  .title {\n    font-family: Poppins;\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 30px;\n    letter-spacing: 0.03em;\n    text-align: left;\n  }\n  .infinite-scroll-component__outerdiv {\n    margin-top: -36px;\n  }\n  .graph_container {\n    margin-top: 40px;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 10px;\n    overflow: hidden !important;\n    @media (max-width: 1200px) {\n      & {\n        grid-template-columns: 1fr;\n      }\n    }\n  }\n}\n\n.no-widget-found {\n  font-size: 18px;\n  line-height: 14px;\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
