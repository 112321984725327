import React, { useEffect, useState } from "react";
import Cards from "src/micro/Cards/Cards";

import "./microCardGroup.scss";
import {
  deleteWidgetFromDashboard,
  getCompanyCreationData,
  getContactCreationData,
  getDashboardLists,
  getTaskCreationData,
  getTicketCreated,
  getTotalTicketCreatedByLastDate,
  moveReport,
  renameReport,
} from "src/common/analytics-and-dashboard-api-calls";
import HasError from "src/common/components/hasError/HasError";
import LoadingComponent from "src/common/components/loading/Loading";
import {
  ILayoutsPNew,
  IWidgetInfo,
  analyticsEndpoints,
} from "src/common/types/analytics.types";
import SnippetsHeaderActions from "src/micro/snippets/SnippetsHeaderActions";
import { Button, Divider, Input, Modal, SelectPicker } from "rsuite";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import FilterModal from "src/components/FilterModals/FilterModal";
interface IMicroCardGroup {
  title?: string;
  endpoint?: string;
  description?: string;
  widgetData?: IWidgetInfo;
  layoutsPassToTemplate?: ILayoutsPNew;
}
const MicroCardGroup: React.FC<IMicroCardGroup> = ({
  title,
  endpoint,
  description,
  widgetData,
  layoutsPassToTemplate,
}: IMicroCardGroup) => {
  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(title ?? "");
  const [isModalOpenForOptions, setIsModalOpenForOptions] = useState(false);
  const [optionUpdateBtnDisable, setOptionUpdateBtnDisable] = useState(true);
  const [selectedOption, setSelectedOption] = useState("rename");
  const [hasError, setHasError] = useState(false);
  const [selectedDashBoard, setSelectedDashboard] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [colorGround, setColorGround] = useState({
    forGroundColor: widgetData?.foreground_color ?? "#1D3D70",
    backGroundColor: widgetData?.background_color ?? "#ffffff",
  });
  const getCardData = async () => {
    if (endpoint === analyticsEndpoints.total_contact_created_vs_last_week) {
      const response = await getContactCreationData();
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // let filteredData = [];
        // if (response.data?.length > 0) {
        //   filteredData = response.data.filter((item: any) => item !== null);
        // }
        // setCardData(filteredData);
        setCardData(response.data);
      }
    }
    if (endpoint === analyticsEndpoints.total_task_created_vs_last_week) {
      const response = await getTaskCreationData();
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // let filteredData = [];
        // if (response.data?.length > 0) {
        //   filteredData = response.data.filter((item: any) => item !== null);
        // }
        // setCardData(filteredData);
        setCardData(response.data);
      }
    }

    if (endpoint === analyticsEndpoints.total_company_created_vs_last_week) {
      const response = await getCompanyCreationData();
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // let filteredData = [];
        // if (response.data?.length > 0) {
        //   filteredData = response.data.filter((item: any) => item !== null);
        // }
        // setCardData(filteredData);
        setCardData(response.data);
      }
    }

    if (endpoint === analyticsEndpoints.TOTAL_TICKET_CREATED_VS_LAST_WEEK) {
      const response = await getTotalTicketCreatedByLastDate();
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setCardData(response.data);
      }
    }
  };

  const getDashboardInfo = async () => {
    const response = await getDashboardLists();
    if (response.data.length > 0) {
      let modified: any = [];
      response.data.map((data: any) => {
        return modified.push({
          value: data.id,
          label: data.dashboard_name,
        });
      });
      setDashboards(modified);
    }
  };

  const handleWidgetDelete = async () => {
    setIsLoading(true);
    const response = await deleteWidgetFromDashboard(
      id,
      widgetData?.user_widget_association_id
        ? widgetData?.user_widget_association_id
        : 0
    );

    if (response.error === true) {
      setIsLoading(false);
      toast.error("Failed to delete widget from dashboard.");
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleFilterOpeningModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleReportRename = async () => {
    setOptionUpdateBtnDisable(true);
    const response = await renameReport(
      widgetData?.user_widget_association_id as number,
      reportTitle as string
    );

    if (response.error === true) {
      toast.error("Failed to rename report.");
      setIsLoading(false);
      setOptionUpdateBtnDisable(false);
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleReportMove = async () => {
    setOptionUpdateBtnDisable(true);
    const response = await moveReport(
      id,
      widgetData?.user_widget_association_id as number,
      selectedDashBoard
    );

    if (response.error === true) {
      toast.error("Failed to move report.");
      setIsLoading(false);
      setOptionUpdateBtnDisable(false);
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleModalOpenerForOptions = (option: string) => {
    setSelectedOption(option);
    setIsModalOpenForOptions(true);
  };

  useEffect(() => {
    if (selectedOption === "move") {
      getDashboardInfo();
    }
  }, [selectedOption]);

  useEffect(() => {
    getCardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchFilterData = (filters: string) => {
    setIsLoading(true);
    getCardData();
  };
  console.log("cardData 000000000000", cardData);
  let cardsData: any = [
    {
      "Total Ccontact Created": 0,
    },
    {
      "Total Contact Created Last Week": 0,
    },
    null,
    {
      "Average Contact Created Per Month": null,
    },
  ];
  return (
    <>
      <Modal
        className="__generic_modal_v2"
        autoFocus
        show={isModalOpenForOptions}
        onHide={() => {
          setIsModalOpenForOptions(false);
        }}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header className="modal-header-custom">
          <Modal.Title>
            {selectedOption === "rename"
              ? `Rename your dashboard`
              : "Move your report"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0 40px 1px", minHeight: "180px" }}>
          {selectedOption === "rename" && (
            <>
              <div className="title_for_rt">Rename this report *</div>
              <Input
                value={reportTitle}
                onChange={(eve) => {
                  if (optionUpdateBtnDisable) {
                    setOptionUpdateBtnDisable(false);
                  }
                  setReportTitle(eve);
                }}
                style={{ marginTop: "10px", padding: "10px" }}
              />
            </>
          )}

          {selectedOption === "move" && (
            <>
              <div className="title_for_rt">Move this report *</div>
              <SelectPicker
                style={{
                  marginTop: "15px",
                  width: "320px",
                }}
                data={dashboards}
                cleanable={false}
                onSelect={(value) => {
                  if (optionUpdateBtnDisable) {
                    setOptionUpdateBtnDisable(false);
                  }
                  setSelectedDashboard(value);
                }}
              />
            </>
          )}

          <Divider />
          <div
            className="actions"
            style={{ display: "flex", gap: "10px", marginBottom: "25px" }}
          >
            <Button
              color="green"
              appearance="default"
              disabled={optionUpdateBtnDisable}
              onClick={() => {
                if (selectedOption === "rename") handleReportRename();
                if (selectedOption === "move") handleReportMove();
              }}
            >
              {selectedOption === "rename" ? "rename" : "Move"}
            </Button>
            {/* <Button color="orange" onClick={() => {}}>
              cancel
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>
      <FilterModal
        isFilterModalOpen={isFilterModalOpen}
        handleFilterOpening={handleFilterOpeningModal}
        title={description}
        chart={
          <div className="__mcg_container filter__mcg_container">
            {" "}
            <div className="content" style={{ height: "100%" }}>
              {/* {cardData?.map((data) => {
                return (
                  <Cards
                    title={Object.keys(data)[0]}
                    value={data[Object.keys(data)[0]]}
                  />
                );
              })} */}
              {cardData &&
                cardData
                  ?.filter((data) => data !== null && typeof data === "object")
                  ?.map((data, index) => {
                    if (Object.keys(data).length > 0) {
                      const key = Object.keys(data)[0];
                      return (
                        <Cards key={index} title={key} value={data[key]} />
                      );
                    }
                    return null;
                  })}
            </div>
          </div>
        }
        isLoading={isLoading}
        fetchFilterData={fetchFilterData}
        widgetData={widgetData}
        colorGround={colorGround}
        setColorGround={setColorGround}
        allowTableBgStyle={true}
        rangeValue={widgetData?.date_range || 30}
        allowFilterTab={true}
      />

      {isLoading && !hasError && <LoadingComponent message="Fetching Data" />}
      {!isLoading && hasError && <HasError />}
      {!isLoading && !hasError && (
        <div className="__mcg_container">
          <div className="__title_with_option">
            <div className="__container">
              <div className="__table_snip_container_title" title={title}>
                {id !== undefined && (
                  <div className="drag">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 3C6.27614 3 6.5 2.77614 6.5 2.5C6.5 2.22386 6.27614 2 6 2C5.72386 2 5.5 2.22386 5.5 2.5C5.5 2.77614 5.72386 3 6 3Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 6.5C6.27614 6.5 6.5 6.27614 6.5 6C6.5 5.72386 6.27614 5.5 6 5.5C5.72386 5.5 5.5 5.72386 5.5 6C5.5 6.27614 5.72386 6.5 6 6.5Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 10C6.27614 10 6.5 9.77614 6.5 9.5C6.5 9.22386 6.27614 9 6 9C5.72386 9 5.5 9.22386 5.5 9.5C5.5 9.77614 5.72386 10 6 10Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 3C9.77614 3 10 2.77614 10 2.5C10 2.22386 9.77614 2 9.5 2C9.22386 2 9 2.22386 9 2.5C9 2.77614 9.22386 3 9.5 3Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 6.5C9.77614 6.5 10 6.27614 10 6C10 5.72386 9.77614 5.5 9.5 5.5C9.22386 5.5 9 5.72386 9 6C9 6.27614 9.22386 6.5 9.5 6.5Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 10C9.77614 10 10 9.77614 10 9.5C10 9.22386 9.77614 9 9.5 9C9.22386 9 9 9.22386 9 9.5C9 9.77614 9.22386 10 9.5 10Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.5 3C2.77614 3 3 2.77614 3 2.5C3 2.22386 2.77614 2 2.5 2C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.5 6.5C2.77614 6.5 3 6.27614 3 6C3 5.72386 2.77614 5.5 2.5 5.5C2.22386 5.5 2 5.72386 2 6C2 6.27614 2.22386 6.5 2.5 6.5Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.5 10C2.77614 10 3 9.77614 3 9.5C3 9.22386 2.77614 9 2.5 9C2.22386 9 2 9.22386 2 9.5C2 9.77614 2.22386 10 2.5 10Z"
                        stroke="#1D3D70"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                )}
                <div title={title}>
                  {layoutsPassToTemplate
                    ? layoutsPassToTemplate.w > 2
                      ? title
                      : `${title?.slice(0, 14)}...`
                    : title}
                </div>
              </div>

              <SnippetsHeaderActions
                handleFilterOpening={handleFilterOpeningModal}
                handleWidgetDelete={handleWidgetDelete}
                handleReportReload={getCardData}
                handleModalOpenerForOptions={handleModalOpenerForOptions}
                showFilter={false}
              />
            </div>
          </div>
          <div
            className="content"
            style={
              colorGround?.backGroundColor
                ? {
                    backgroundColor: colorGround.backGroundColor,
                    height: "100%",
                  }
                : { height: "100%" }
            }
          >
            {cardData &&
              cardData
                ?.filter((data) => data !== null && typeof data === "object")
                ?.map((data, index) => {
                  if (Object.keys(data).length > 0) {
                    const key = Object.keys(data)[0];
                    return <Cards key={index} title={key} value={data[key]} />;
                  }
                  return null;
                })}
          </div>
        </div>
      )}
    </>
  );
};

export default MicroCardGroup;
