/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container, Content, Dropdown, Header } from "rsuite";
import NoWidget from "../assets/images/no-widget.png";

import DashboardHeaderComponent from "src/components/headers/DashboardHeader";

import { getDashboardDetails } from "src/common/analytics-and-dashboard-api-calls";
import LoadingComponent from "src/common/components/loading/Loading";

import { GlobalContext } from "src/App";
import GridLayout from "src/common/components/GridLayout/GridLayout";

import "./styles/dashboard.scss";
import HasErrorOnDashboard from "src/common/components/hasErrorOnDashboard/HasErrorOnDashboard";
// import { dashboard_details } from "src/mocks/mock-data";

interface IWidget {
  id: string;
  content: React.ReactNode;
  size: number;
}

const Dashboard: React.FC = () => {
  const history = useHistory();

  const {
    setIsLoading: setIsLoadingForDashboardChange,
    isLoading: globalIsLoading,
  } = useContext(GlobalContext);
  const { id }: any = useParams();
  const [widgets, setWidgets] = useState<IWidget[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [layoutInfo, setLayoutInfo] = useState<any>([{}]);

  const fetchDashboardData = async () => {
    try {
      const response: any = await getDashboardDetails(id); // uncomment must after checking done ===============
      // const response: any = {
      //   data: dashboard_details
      // }
      if (response.error === true) {
        setIsLoadingForDashboardChange(false);
        setHasError(true);
        setIsLoading(false);
      } else {
        const _layout = response?.data?.dashboard?.layout;
        let _widgetData = response.data?.widgets;
        let filteredLayout = _layout?.filter((itemData: any) =>
          _widgetData?.some(
            (item: any) => item.user_widget_association_id === itemData.id
          )
        );
        if (filteredLayout?.length > 0) {
          setLayoutInfo(filteredLayout);
        } else {
          setLayoutInfo(_layout);
        }

        let _widget = response.data.widgets;
        _widget.sort((a: any, b: any) => {
          const positionA = response.data.dashboard?.widget_rank?.indexOf(
            Number(a.id)
          );
          const positionB = response.data.dashboard?.widget_rank?.indexOf(
            Number(b.id)
          );
          return positionA - positionB;
        });
        setWidgets(_widget);
        setIsLoadingForDashboardChange(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fetchDashboardData.error", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDashboardData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Content className="main-content">
        <Container>
          <Header>
            <DashboardHeaderComponent hasError={hasError} />
          </Header>
          {globalIsLoading && (
            <LoadingComponent message="Fetching Your Dashboard Data" />
          )}
          {globalIsLoading && isLoading && !hasError && (
            <LoadingComponent message="Fetching Your Dashboard Data" />
          )}
          {!globalIsLoading && !isLoading && hasError && (
            <HasErrorOnDashboard
              key={"has error"}
              message="This dashboard does not exist on your account"
            />
          )}

          {!globalIsLoading &&
            !isLoading &&
            !hasError &&
            widgets.length !== 0 && (
              <GridLayout
                key={"xx-01-7d"}
                layouts={layoutInfo as any}
                data={widgets}
                dashboardId={id}
              />
            )}

          {!globalIsLoading &&
            !isLoading &&
            !hasError &&
            widgets.length === 0 && (
              <>
                <div
                  className="no-widget-found-container"
                  style={{
                    display: "flex",
                    height: "50vh",
                    width: "100vw",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "30px",
                  }}
                >
                  <div className="img-container">
                    <img
                      src={NoWidget}
                      alt="no-widget-found"
                      style={{ height: "180px" }}
                    />
                  </div>
                  <div>No associated reports found. To visualize your data</div>
                  <Dropdown title="Add Report" appearance="default" size="lg">
                    <Dropdown.Item
                      className="w-250px"
                      onSelect={() => {
                        history.push(`/report-lists?fromDashboard=${id}`);
                      }}
                    >
                      From saved reports
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="w-250px"
                      onSelect={() => {
                        history.push(`/report-creation?fromDashboard=${id}`);
                      }}
                    >
                      Create report
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </>
            )}
        </Container>
      </Content>
    </>
  );
};

export default Dashboard;
