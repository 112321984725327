/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, memo, useRef } from "react";
import { registeredWidgets } from "src/widget.register";
import { Responsive, WidthProvider, Layouts, Layout } from "react-grid-layout";
import TemplateRenderer from "src/components/TemplateRenderer/TemplateRenderer";
import { updateRankPositionOfDashboardWidget } from "src/common/analytics-and-dashboard-api-calls";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {
  data: any[];
  layouts?: Layouts | any;
  dashboardId: string;
}

const GridLayout: React.FC<GridLayoutProps> = ({
  data,
  layouts: _layout,
  dashboardId,
}) => {
  console.log("_", _layout, _layout?.length, data?.length);

  const isModified = useRef(false);

  const [cellsToShow, setCellsToShow] = useState(1);

  const [layouts, setLayouts] = useState<Layouts>({});
  const [layoutsIndication, setLayoutsIndication] = useState<Layouts>({});
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [layoutsNew, setLayoutsNew] = useState<Layout[]>([]);
  const [mainData, setMainData] = useState<Layout[]>(data);

  const generateLayout = useCallback(
    (_: string) => {
      return data.map((item: any, index) => ({
        i: `layout-item-${index}`,
        x: (index * 6) % 12,
        y: Math.floor(index / 2),
        w: 6,
        h: 4,
        minW: 2,
        minH: 1,
        id: item.user_widget_association_id,
      }));
    },
    [data]
  );

  const generateLayoutForView = useCallback(() => {
    const _ = new Array(cellsToShow).fill("");
    return _.map((_, index) => ({
      i: `layout-item-${index}-${index}`,
      x: (index * 1) % 12,
      y: Math.floor(index / 2),
      w: 1,
      h: 1,
      id: "",
    }));
  }, [cellsToShow]);

  const handleBreakPointChange = useCallback(
    (newBreakpoint: string) => {
      if (_layout?.length > 0 && _layout[0].i !== undefined) {
        const existingLayouts = _layout || [];
        const newLayouts = data.map((widget, index) => {
          const matchingLayout = existingLayouts.find(
            (layoutItem: any) =>
              layoutItem.id === widget.user_widget_association_id
          );
          if (matchingLayout) {
            return {
              h: matchingLayout.h,
              i: `layout-item-${index}`,
              w: matchingLayout.w,
              x: matchingLayout.x,
              y: matchingLayout.y,
              moved: false,
              static: false,
              id: matchingLayout.id,
            };
          } else {
            const isEven = index % 2 === 0;
            const maxY = existingLayouts.reduce(
              (max: any, layoutItem: any) => Math.max(max, layoutItem.y),
              0
            );
            return {
              h: 4,
              i: `layout-item-${index}`,
              w: 6,
              x: isEven ? 0 : 6,
              y: maxY + 1,
              moved: false,
              static: false,
              id: widget.user_widget_association_id,
            };
          }
        });
        setLayouts((prevLayouts) => ({
          ...prevLayouts,
          [newBreakpoint]: newLayouts,
        }));
      } else {
        const newLayout = generateLayout(newBreakpoint);
        setLayouts((prevLayouts) => ({
          ...prevLayouts,
          [newBreakpoint]: newLayout,
        }));
      }
    },
    [dashboardId, _layout]
  );

  const handleLayoutChange = useCallback(
    async (newLayout: Layout[], _: Layouts, breakpoint: string, _layout) => {
      const updatedLayout = newLayout.map((newLayoutItem) => {
        const matchedLayoutDataItem: any = Object.keys(layouts).reduce(
          (acc: any, breakpoint: any) => {
            if (acc) return acc; // If a match was found, stop further searching
            const value = layouts[breakpoint]?.find(
              (layoutDataItem: any) => layoutDataItem.i === newLayoutItem.i
            );
            return value || acc; // Return the value if found, otherwise keep the accumulator
          },
          null // Start with null as the accumulator
        );
        if (matchedLayoutDataItem && matchedLayoutDataItem.id) {
          return {
            ...newLayoutItem,
            id: matchedLayoutDataItem.id,
          };
        }
        return newLayoutItem;
      });
      if (isModified.current) {
        await updateRankPositionOfDashboardWidget(
          Number(dashboardId),
          [],
          updatedLayout
        );
      }
      setLayoutsNew(updatedLayout);
    },
    [dashboardId, layouts]
  );

  const onDragStart = useCallback(() => {
    setIsDragging(true);
    isModified.current = true;
  }, []);

  const onDragStop = useCallback(() => {
    setIsDragging(false);
    isModified.current = true;
  }, []);

  useEffect(() => {
    if (dashboardId) {
      const persistedLayouts = Object.keys(layouts).reduce(
        (acc, breakpoint) => {
          if (_layout !== null) {
            acc[breakpoint] = JSON.parse(JSON.stringify(_layout));
          } else {
            const newLayout = generateLayout("lg");
            acc[breakpoint] = newLayout;
          }
          // const newLayout = generateLayout("lg");
          // acc[breakpoint] = newLayout;
          return acc;
        },
        {} as Layouts
      );
      setLayouts(persistedLayouts);
    }
  }, [data, dashboardId, generateLayout, _layout]);

  useEffect(() => {
    const initialLayoutForIndication = generateLayoutForView();
    setLayoutsIndication({ lg: initialLayoutForIndication });
  }, [cellsToShow, generateLayoutForView]);

  useEffect(() => {
    setCellsToShow(data?.length * 6 * 5);
  }, [data]);

  const reorderDataById = (data: any, layouts: any) => {
    return layouts
      .map((layout: any) =>
        data?.find((item: any) => item.user_widget_association_id === layout.id)
      )
      .filter((item: any) => item !== undefined);
  };

  useEffect(() => {
    let reorderedData: any = [];
    if (data?.length !== layoutsNew?.length) {
      let layoutsData: any = [];
      Object.keys(layouts)?.forEach((breakpoint) => {
        layouts[breakpoint].forEach((item) => {
          layoutsData.push(item);
        });
      });
      reorderedData = reorderDataById(data, layoutsData);
    } else {
      reorderedData = reorderDataById(data, layoutsNew);
    }
    if (reorderedData?.length > 0) {
      setMainData(reorderedData);
    }
  }, [layouts]);
  // console.log("widgetData 0133 mainData 00111", mainData)
  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        onResizeStart={onDragStart}
        onResizeStop={onDragStop}
        onBreakpointChange={handleBreakPointChange}
        onLayoutChange={handleLayoutChange as any}
        draggableHandle=".drag"
        breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
      >
        {mainData.map((item: any, index: number) => {
          return (
            registeredWidgets.includes(item?.widget_name) && (
              <div
                key={`layout-item-${index}`}
                className="grid-item"
                style={{ border: "0.7px solid #D1D8E3", background: "white" }}
              >
                <div
                  className="grid-item__title drag"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <TemplateRenderer
                    item={item}
                    key={item?.widget_name}
                    {...(layoutsNew
                      ? { layoutsPassToTemplate: layoutsNew[index] }
                      : {})}
                  />
                </div>
              </div>
            )
          );
        })}
      </ResponsiveGridLayout>

      {isDragging && (
        <div>
          <div
            className="indication-layout"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <div style={{ flex: 1, zIndex: "-1" }}>
              <ResponsiveGridLayout
                className="guide-grid-inner-layout"
                layouts={layoutsIndication}
                breakpoints={{ lg: 1280 }}
                cols={{ lg: 12 }}
                margin={[10, 10]}
                isDraggable={false}
                isResizable={false}
                useCSSTransforms={true}
              >
                {new Array(cellsToShow).fill("").map((item, index) => (
                  <div
                    key={`layout-item-${index}-${index}`}
                    className="grid-item"
                    style={{ border: "1px solid gray", opacity: ".3" }}
                  ></div>
                ))}
              </ResponsiveGridLayout>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(GridLayout);
